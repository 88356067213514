/* For mobile phones */
@media only screen and (max-width: 600px) {
  body {
    font-size: 14px;
  }
  .container {
    width: 95%;
    padding: 10px;
  }
  .nav-links {
    flex-direction: column;
    align-items: flex-start;
  }
  .nav-item {
    margin-left: 0;
    margin-bottom: 10px;
  }
  .hamburger {
    display: flex;
  }
}

/* For tablets */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  body {
    font-size: 16px;
  }
  .container {
    width: 90%;
    padding: 15px;
  }
  .hamburger {
    display: none;
  }
}

/* For desktop */
@media only screen and (min-width: 1025px) {
  body {
    font-size: 18px;
  }
  .container {
    width: 80%;
    max-width: 1200px;
    padding: 20px;
  }
  .hamburger {
    display: none;
  }
}

body {
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    color: #ffffff;
    background-color: #000000;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Orbitron', sans-serif;
    color: #ffcc00;
}

h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

h2 {
    font-size: 2.5rem;
    margin-bottom: 0.75rem;
}

.button {
    font-family: 'Orbitron', sans-serif;
    background-color: #ffcc00;
    color: #000;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
}

.button:hover {
    background-color: #ffaa00;
}

header, footer {
    text-align: center;
    padding: 20px;
    background-color: #111;
}

main {
    padding: 20px;
}

section {
    margin-bottom: 20px;
}

p, ul {
    margin: 0 0 1rem 0;
}

/* Social Media Links */
footer div {
    margin-top: 20px;
}

footer a {
    margin: 0 10px;
    color: #ffcc00;
    text-decoration: none;
    font-family: 'Orbitron', sans-serif;
}

footer a:hover {
    color: #ffaa00;
}
