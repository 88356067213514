/* Base styles */
body {
  font-size: 16px;
}

/* For mobile phones */
@media only screen and (max-width: 600px) {
  body {
    font-size: 14px;
  }
  .container {
    width: 95%;
    padding: 10px;
  }
}

/* For tablets */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  body {
    font-size: 16px;
  }
  .container {
    width: 90%;
    padding: 15px;
  }
}

/* For desktop */
@media only screen and (min-width: 1025px) {
  body {
    font-size: 18px;
  }
  .container {
    width: 80%;
    max-width: 1200px;
    padding: 20px;
  }
}